<template>
  <LoadingState v-if="isLoading" />
  <a-row v-else class="extracurricular">
    <a-modal
      centered
      :visible="modalSavingGradeVisible"
      @ok="handleOkSaveGrading"
      @cancel="toggleModalSaveGrading"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Save"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" />
        SAVING GRADE CONFIRMATION
      </h3>
      <p>
        Are you sure to save grading? Your data before will be lost once you
        update.
      </p>
    </a-modal>
    <a-col :span="24">
      <div class="coverDetail">
        <div>
          <h2>Extracurricular | {{ header.nama }}</h2>
          <div>
            {{ header.totalStudent }} Students | {{ header.tahunAjaran }}
          </div>
        </div>
        <template> </template>
      </div>
      <div class="contentDetail">
        <div class="detail-slot">
          <div class="d-flex align-items-center mb-4">
            <a-button
              @click.prevent="toManageEkskulParticipant"
              icon="folder-open"
              size="large"
              class="ml-auto"
              type="primary"
              >MANAGE</a-button
            >
            <div v-if="dataTable.length" class="ml-3">
              <template v-if="!isGrading">
                <a-button @click.prevent="toggleGrade" icon="edit" size="large"
                  >GRADE</a-button
                >
              </template>
              <template v-else>
                <div class="d-flex">
                  <a-button
                    @click.prevent="cancelGrading"
                    :loading="cancelGradingLoading"
                    class="mr-3"
                    icon="close"
                    size="large"
                    type="danger"
                    >{{
                      cancelGradingLoading ? "CANCELLING..." : "CANCEL"
                    }}</a-button
                  >
                  <a-button
                    @click.prevent="toggleModalSaveGrading"
                    :loading="savingGradeLoading"
                    icon="save"
                    size="large"
                    type="primary"
                    >{{ savingGradeLoading ? "SAVING..." : "SAVE" }}</a-button
                  >
                </div>
              </template>
            </div>
          </div>
          <a-table
            :columns="columns"
            :data-source="dataTable"
            :loading="loadingTable"
            :pagination="pagination"
            @change="handleTableChange"
            bordered
          >
            <div slot="nilai" slot-scope="text, record">
              <a-select
                size="large"
                v-if="isGrading"
                :default-value="text"
                @change="(e) => handleChange(e, record.key, 'nilai')"
                style="width: 80px"
              >
                <a-select-option
                  v-for="item in grades"
                  :key="item.id"
                  :value="item.grade"
                  >{{ item.grade }}</a-select-option
                >
              </a-select>
              <span v-else>
                {{ text }}
              </span>
            </div>
            <!-- <div slot="in_number" slot-scope="text, record">
              <a-input-number v-if="isGrading" :default-value="text" :min="2" size="large" :max="4" @change="e => handleChange(e, record.key, 'in_number')" /> <span v-if="isGrading"></span>
              <span v-else>
                {{text}}
              </span>
            </div> -->
            <!-- <div slot="notes" slot-scope="text, record">
              <a-input
                v-if="isGrading"
                :value="text"
                size="large"
                @change="
                  (e) => handleChange(e.target.value, record.key, 'notes')
                "
              />
              <span v-else>
                {{text}}
              </span>
            </div> -->
          </a-table>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import LoadingState from '@/components/app/LoadingState'
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    width: 100,
  },
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'class',
    width: 80,
    align: 'center',
  },
  // {
  //   title: 'In Number',
  //   dataIndex: 'in_number',
  //   key: 'inNumber',
  //   width: 70,
  //   align: 'center',
  //   scopedSlots: { customRender: 'in_number' },
  // },
  // {
  //   title: 'In Word',
  //   dataIndex: 'in_word',
  //   key: 'inWord',
  //   width: 70,
  //   align: 'center',
  //   scopedSlots: { customRender: 'in_word' },
  // },
  {
    title: 'Grade',
    dataIndex: 'nilai',
    key: 'nilai',
    width: 70,
    align: 'center',
    scopedSlots: { customRender: 'nilai' },
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 180,
    scopedSlots: { customRender: 'notes' },
  },
]
const grades = [
  {
    id: 1,
    grade: 'A',
  },
  {
    id: 2,
    grade: 'B',
  },
  {
    id: 3,
    grade: 'C',
  },
  // {
  //   id: 4,
  //   grade: 'D',
  // },
  // {
  //   id: 5,
  //   grade: 'E',
  // },
]
export default {
  components: {
    LoadingState,
  },
  data() {
    return {
      columns,
      grades,
      dataTable: [],
      dataPesertaFull: [],
      header: {
        nama: '',
        tahunAjaran: '',
        totalStudent: '',
      },
      pagination: {},
      loadingTable: false,
      isGrading: false,
      modalSavingGradeVisible: false,
      savingGradeLoading: false,
      cancelGradingLoading: false,
      search: '',
    }
  },
  methods: {
    toManageEkskulParticipant() {
      this.$router.push({ name: 'ECA Supervisor Teacher Manage Students' })
    },
    handleChange(value, key, column = 'in_number') {
      const newData = [...this.dataPesertaFull]
      const dataTable = [...this.dataTable]
      const target = newData.filter(item => key === item.id)[0]
      const targetTable = dataTable.filter(item => key === item.key)[0]
      // let word
      let notes
      console.log(value)
      if (target) {
        // if (column === 'in_number') {
        //   if (value === 2) {
        //     word = 'Average'
        //   } else if (value === 3) {
        //     word = 'Good'
        //   } else if (value === 4) {
        //     word = 'Excellent'
        //   }
        //   target.in_word = word
        //   targetTable.in_word = word
        // }
        if (column === 'nilai') {
          if (value === 'A') {
            notes = 'Very skillful in applying all the competencies learned.'
          } else if (value === 'B') {
            notes = 'Skilled in applying all the competencies learned.'
          } else if (value === 'C') {
            notes = 'Quite skilled in applying all the competencies learned.'
          }
          target.notes = notes
          targetTable.notes = notes
        }
        target[column] = value
        this.savedData = newData
        console.log(this.savedData)
      }
    },
    cancelGrading() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to cancel grading ? your changes will be lost</div>
        ),
        onOk: () => {
          this.cancelGradingLoading = true
          this.fetchListPeserta()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    handleOkSaveGrading() {
      console.log(this.savedData)
      this.savingGradeLoading = true
      this.toggleModalSaveGrading()
      this.$store.dispatch('extracurricular/PUT_GRADING_EXTRACURRICULAR', {
        savedData: this.savedData,
      })
        .then(isSuccess => {
          if (isSuccess) {
            this.$notification.success({
              message: 'Successfully update grading',
            })
          } else {
            this.$notification.error({
              message: 'Failed update grading',
            })
          }
          this.savingGradeLoading = false
          this.fetchListPeserta()
        })
    },
    toggleModalSaveGrading() {
      this.modalSavingGradeVisible = !this.modalSavingGradeVisible
    },
    toggleGrade() {
      this.isGrading = !this.isGrading
    },
    searchStudent() {
      // if (this.search.length > 2) {
      this.fetchListPeserta({
        search: this.search,
      })
      // } else {
      //   this.fetchData()
      // }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchListPeserta({
        page: pagination.current,
        order: order,
      })
    },
    fetchHeader() {
      this.$store.dispatch('extracurricular/FETCH_HEADER_EXTRACURRICULAR', { idGuru: this.user.id })
        .then(res => {
          console.log('res', res)
          this.header = {
            nama: res?.ekstrakurikuler?.nama,
            tahunAjaran: `${res.tahun_ajaran.tahun} (${res.tahun_ajaran.semester})`,
            totalStudent: 11,
          }
        })
    },
    fetchListPeserta(params = { page: 1, order: 'ASC', search: '' }) {
      this.loadingTable = true
      this.$store.dispatch('extracurricular/FETCH_LIST_PESERTA_EXTRACURRICULAR', { idGuru: this.user.id, page: params.page, order: params.order, search: params.search })
        .then(data => {
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loadingTable = false
          this.cancelGradingLoading = false
          this.isGrading = false
          this.dataPesertaFull = data.list_peserta_full
          this.dataTable = data.list_peserta.map(row => {
            return {
              key: row.id,
              // nis: row.kelas_tahun_ajaran.murid.nis,
              name: row.kelas_tahun_ajaran.murid.nama,
              gender: row.kelas_tahun_ajaran.murid.jenis_kelamin,
              class: `${row.kelas_tahun_ajaran.kela.tingkat}-${row.kelas_tahun_ajaran.kela.simbol}`,
              // in_number: row.in_number,
              // in_word: row.in_word,
              nilai: row.nilai,
              notes: row.notes,
            }
          })
          this.pagination = pagination
        })
    },
  },
  mounted() {
    this.fetchHeader()
    this.fetchListPeserta()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
  },
}
</script>
<style lang="scss">
.extracurricular {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }
}
</style>
